import {useState} from 'react';
import {Map, Source, Marker, Layer} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MainMap.css';
import MAP_STYLE from './MainMap-style';
import { useTranslation } from 'react-i18next';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY

function AdministrativeMap(){
  return (
    <Map
        initialViewState={{
          latitude: 56.946,
          longitude: 24.105,
          zoom: 7
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle={MAP_STYLE as any}
        interactiveLayerIds={['lv-administrative-fill']}
      >
    </Map>
  );
}
function HeatmapMap(){
  const leftCorner=[21,57.75];
  const width=6.6;
  const height=2;
  return (
    
    <Map
        initialViewState={{
          latitude: 56.946,
          longitude: 24.105,
          zoom: 7
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
    >
      <Source
        id="map-source"
        type="image"
        url="heatmap.png"
        coordinates={[
          leftCorner,
          [leftCorner[0]+width, leftCorner[1]],
          [leftCorner[0]+width, leftCorner[1]-height],
          [leftCorner[0], leftCorner[1]-height]
        ]}
      />
      <Layer
        id="overlay"
        source="map-source"
        type="raster"
        paint={{ "raster-opacity": 0.85 }}
      />
    </Map>
    
  );
}
function HeatmapMapLegend(){
  return (
    <div className="legend-container">
        <div className="gradient"></div>
        <div className="value-tickmarks">
          <div className="value-tickmark"></div>
          <div className="value-tickmark small-tickmark"></div>
          <div className="value-tickmark"></div>
          <div className="value-tickmark small-tickmark"></div>
          <div className="value-tickmark"></div>
        </div>
        <div className="value-container">
          <div className="value">1</div>
          <div className="value">10</div>
        </div>
      </div>
  )
}

function InfrastructureMap(){
  return (
    
    <Map
        initialViewState={{
          latitude: 56.946,
          longitude: 24.105,
          zoom: 7
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
    >
    </Map>
  )
}


function MainMap() {
  const { t } = useTranslation();
  const maps = [
  {
    'name':t('scoremap'),
    'map': <AdministrativeMap/>,
    'legend' : <HeatmapMapLegend/>
  }, 
  {
    'name':t('predmap'),
    'map': <HeatmapMap/>
  }, 
  {
    'name':t('inframap'),
    'map': <InfrastructureMap/>
  }
  ];
  const [selectedMap, setSelectedMap] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const selectMap = (index: number) => {
    setSelectedMap(index);
    setDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <div className="map-container">
      {maps[selectedMap].map}
      
      <div className="map-info-container">
        <div className="map-info" onClick={toggleDropdown}>
          <div className="map-info-text">{maps[selectedMap].name}</div>
          <img className={`caret ${dropdownOpen ? 'open' : ''}`} src="/caret.svg" alt="caret"/>
        </div>
        {dropdownOpen&&maps
          .map((map, index) => {
            if(index !== selectedMap){
              return (
                <div className="map-info" key={index} onClick={() => selectMap(index)}>
                  <div className="map-info-text">{map.name}</div>
                </div>
              )
            }
          })}
      </div>
      {maps[selectedMap].legend}
    </div>
  );
}

export default MainMap;
