import React from 'react';
import MainMap from './MainMap';
import './App.css';
import { useTranslation } from 'react-i18next';
//import { handleChangeLanguage } from '../i18n';

function App() {
  const { t } = useTranslation();
  
  return (
    <div className="App">      
      <nav className="menu">
        <div className="logo">
          <img src="/logo.svg"/>
        </div>
        <div className="list-container">
          <ul className="list">
            <li className="selected"><div><img className="icon" src="/map.svg"/><a href="#" className='NavText'>{t('map')}</a></div></li>
            <li><div><img className="icon" src="/cog.svg"/><a href="#" className='NavText'>{t('settings')}</a></div></li>
            <li><div><img className="icon" src="/document.svg"/><a href="#" className='NavText'>{t('documentation')}</a></div></li>
          </ul>
        </div>
        <div className="user">
          <img className="user-picture" src="/user.svg"/>
          <div className="user-info">{t('user')}</div>
          <img className="caret" src="/caret-thin.svg"/>
        </div>
      </nav>
      <MainMap/>
    </div>
  );
}

export default App;
