import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import lvJSON from './locales/lv.json';
import enJSON from './locales/en.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';
import HttpBackend from 'i18next-http-backend';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'cookie',
  lookup(options) {
    return Cookies.get('lang');
  },
});


export function handleChangeLanguage(language : string) {
    const newLang = language;
    Cookies.set('lang', newLang);
    i18n.changeLanguage(newLang);
}


i18n
  .use(HttpBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
        lv: { ...lvJSON },
        en: { ...enJSON },
    },
    lng: "lv",
    fallbackLng: "en",
  });