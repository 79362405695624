import type {GeoJSONSourceRaw} from 'react-map-gl';
import type {LineLayerSpecification, FillLayerSpecification} from 'mapbox-gl';
import MAP_STYLE from '../data/style-mapbox-outdoors-v12.json';
//import MAP_STYLE from './map-style-basic-v8.json';
const lvAdministrative: GeoJSONSourceRaw = {
  type: 'geojson',
  data: 'lv-administrative.geojson'
};

const fillLayer: FillLayerSpecification  = {
  id: 'lv-administrative-fill',
  source: 'lv-administrative',
  type: 'fill',
  paint: {
    'fill-outline-color': '#0040c8',
    'fill-opacity': 0.5,
    "fill-color": ['interpolate-hcl', ['linear'], ['get', 'total'], 0, '#FF1111', 5, '#FFEC15', 10, '#33FF33']
  }
};

const lineLayer: LineLayerSpecification  = {
  id: 'lv-administrative-outline',
  source: 'lv-administrative',
  type: 'line',
  paint: {
    'line-width': 2,
    'line-opacity': 0.8,
    'line-color': '#646464'
  }
};

// Make a copy of the map style
export default {
  ...MAP_STYLE,
  sources: {
    ...MAP_STYLE.sources,
    ['lv-administrative']: lvAdministrative
  },
  layers: [...MAP_STYLE.layers, fillLayer, lineLayer]
};